<template>
  <section>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Szukaj"
      single-line
      hide-details
      class="mb-3 elevation-1 pa-3"
      clearable
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="sales"
      :search="search"
      :footer-props="{
        'items-per-page-text': 'Użytkowników na stronę',
        'items-per-page-options': [10, 20, 50, 100],
      }"
      :items-per-page="10"
      class="elevation-2"
      multi-sort
      :sort-by="['active', 'name']"
      :sort-desc="true"
      :loading="salesTableLoading"
      loading-text="Wczytywanie... Proszę czekać"
      :header-props="headerProps"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.name }}</td>
          <td>{{ item.datetime_start }}</td>
          <td>{{ item.datetime_end }}</td>
          <td>{{ item.discount_percent }} %</td>
          <td>{{ typeToDisplay(item.type) }}</td>
          <td>{{ item.type_name }}</td>
          <td :class="item.active ? 'green--text' : 'red--text'">
            {{ item.active ? "Tak" : "Nie" }}
          </td>
          <td width="10px">
            <v-btn
              block
              dark
              color="red"
              @click.prevent="disableSaleActive(item)"
              :disabled="item.active == 0 ? true : false"
              >Wyłącz</v-btn
            >
          </td>
        </tr>
      </template>

      <template v-slot:footer.page-text="items">
        {{ items.pageStart }}-{{ items.pageStop }} z
        {{ items.itemsLength }}
      </template>
    </v-data-table>
  </section>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Nazwa", value: "name" },
        { text: "Rozpoczęcie", value: "datetime_start" },
        { text: "Zakończenie", value: "datetime_end" },
        { text: "Obniżka (%)", value: "discount_percent" },
        { text: "Grupa", value: "type" },
        { text: "Nazwa z grupy", value: "type_name" },
        { text: "Aktywnośc", value: "active" },
        { text: "Akcje", sortable: false },
      ],
      headerProps: {
        sortByText: "Sortowanie",
      },
    };
  },
  props: {
    sales: {
      type: Array,
      required: true,
    },
    salesTableLoading: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    typeToDisplay(type) {
      if (type == "producer_name") {
        return "Producent";
      } else if (type == "category_name") {
        return "Kategoria";
      } else {
        return "Wszystkie";
      }
    },
    showEdit(item) {
      this.$emit("showEdit", item);
    },
    disableSaleActive(sale) {
      sale.active = 0;
      this.$emit("disableSaleActive", sale);
    },
  },
};
</script>