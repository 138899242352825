<template>
  <section class="cms-customer">
    <CMSNavTop :apiLink="apiLink" />
    <CMSNavSidebar />
    <div style="padding-left: 56px">
      <Alert
        :alertShow="alertShow"
        :alertText="alertText"
        :alertType="alertType"
        @closeAlert="closeAlert"
      />
      <v-container fluid>
        <v-row>
          <v-col class="mt-3"
            ><v-card elevation="2"
              ><v-card-text class="text-h4 text-center text--primary"
                ><v-btn
                  @click.prevent="saleCreateView = true"
                  dark
                  block
                  color="green"
                  x-large
                  >Dodaj promocje <v-icon>mdi-plus-outline</v-icon></v-btn
                ></v-card-text
              ></v-card
            ></v-col
          >
          <v-col class="mt-3"
            ><v-card elevation="2"
              ><v-card-text class="text-h4 text-center text--primary py-5"
                >Ilość aktywnych promocji: {{ activeSales.length }}
              </v-card-text></v-card
            ></v-col
          >
        </v-row>
        <v-row>
          <v-col>
            <SalesTable
              :sales="sales"
              :salesTableLoading="salesTableLoading"
              @disableSaleActive="disableSaleActive"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <SaleCreate
      :apiLink="apiLink"
      :saleCreateView="saleCreateView"
      @getSales="getSales"
      @closeCreateView="closeCreateView"
      @showAlert="showAlert"
    />
  </section>
</template>

<script>
import CMSNavTop from "@/components/CMS/Navigations/CMSNavTop.vue";
import CMSNavSidebar from "@/components/CMS/Navigations/CMSNavSidebar.vue";
import Alert from "@/components/Alert.vue";
import SalesTable from "@/components/CMS/Sales/SalesTable.vue";
import SaleCreate from "@/components/CMS/Sales/SaleCreate.vue";

export default {
  name: "CMSSale",
  components: {
    CMSNavTop,
    CMSNavSidebar,
    Alert,
    SalesTable,
    SaleCreate,
  },
  data() {
    return {
      salesTableLoading: false,
      saleCreateView: false,
      sales: [],
      alertText: "",
      alertType: "success",
      alertShow: false,
    };
  },
  props: {
    apiLink: {
      type: String,
      required: true,
    },
  },
  computed: {
    activeSales() {
      return this.sales.filter((el) => {
        return el.active == 1;
      });
    },
  },
  created() {
    this.getSales();
  },
  methods: {
    closeCreateView() {
      this.saleCreateView = false;
    },
    showAlert(alertText, alertType) {
      this.alertShow = true;
      this.alertText = alertText;
      this.alertType = alertType;
      console.log(alertText);
    },
    closeAlert() {
      this.alertShow = false;
    },
    async disableSaleActive(sale) {
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/sales/updateSale.php`,
        data: {
          token: localStorage.token,
          sale,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data == true) {
              this.$emit("showAlert", `Zmiany zostały zapisane`, "success");
            } else {
              this.$emit(
                "showAlert",
                `Błąd podczas zmiany aktywności promocji. Błąd: ${res.data}`,
                "error"
              );
            }
          } else {
            this.$emit(
              "showAlert",
              `Błąd podczas zmiany aktywności promocji. Kod błędu API: ${res.status}`,
              "error"
            );
          }
        })
        .catch((err) => {
          this.showAlert(
            `Błąd zmiany aktywności promocji. Błąd: ${err}`,
            "error"
          );
        });
    },
    async getSales() {
      this.salesTableLoading = true;
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/sales/getSales.php`,
        data: {
          token: localStorage.token,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.sales = res.data;
          } else {
            this.$emit(
              "showAlert",
              `Błąd podczas pobierania promocji. Kod błędu API: ${res.status}`,
              "error"
            );
          }
        })
        .catch((err) => {
          this.showAlert(`Błąd pobierania promocji. Błąd: ${err}`, "error");
        });
      this.salesTableLoading = false;
    },
  },
};
</script>