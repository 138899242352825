<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @keydown.esc="closeCreateView"
    >
      <v-card ref="form">
        <v-toolbar dark color="secondary">
          <v-btn icon dark @click="closeCreateView">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Dodawanie nowej promocji</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark color="green" @click="validateCreateSale">
              Dodaj
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-container class="mt-8">
          <v-row justify="center">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="name"
                ref="name"
                label="Nazwa promocji"
                clearable
                :loading="loading"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="discount"
                ref="discount"
                label="Wartość promocji (%)"
                type="number"
                suffix="%"
                clearable
                :loading="loading"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="2">
              <v-text-field
                v-model="dateFrom"
                ref="dateFrom"
                label="Data rozpoczęcia"
                type="date"
                clearable
                :loading="loading"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="timeFrom"
                ref="timeFrom"
                label="Czas rozpoczęcia"
                type="time"
                clearable
                :loading="loading"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="dateTo"
                ref="dateTo"
                label="Data zakończenia"
                type="date"
                clearable
                :loading="loading"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="timeTo"
                ref="timeTo"
                label="Czas zakończenia"
                type="time"
                clearable
                :loading="loading"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="type"
                ref="type"
                label="Grupa promocyjna"
                clearable
                :items="types"
                item-text="name"
                item-value="id"
                :loading="loading"
                :rules="[rules.required]"
                @change="getData"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="typeId"
                ref="typeId"
                :label="getTypeIdLabel"
                clearable
                :items="typeData"
                item-text="name"
                item-value="name"
                :loading="dataLoading"
                :rules="[rules.required]"
                :disabled="type == 'all' || type == null ? true : false"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "UserCreate",

  data() {
    return {
      rules: {
        required: (value) => !!value || "Wymagane.",
      },
      dialog: false,
      formHasErrors: false,
      loading: false,
      dataLoading: false,
      dateFrom: this.convertDate(new Date()),
      timeFrom: "00:00",
      dateTo: this.convertDate(new Date()),
      timeTo: "23:59",
      discount: null,
      name: null,
      type: null,
      typeId: null,
      typeData: [],
      types: [
        {
          id: "category_name",
          name: "Kategoria",
        },
        {
          id: "producer_name",
          name: "Producent",
        },
        {
          id: "all",
          name: "Wszystkie",
        },
      ],
    };
  },
  computed: {
    form() {
      return {
        dateFrom: this.dateFrom,
        timeFrom: this.timeFrom,
        dateTo: this.dateTo,
        timeTo: this.timeTo,
        discount: this.discount,
        name: this.name,
        type: this.type,
        typeId: this.typeId,
      };
    },
    getTypeIdLabel() {
      if (this.type == "category_name") {
        return "Wybór kategorii";
      } else if (this.type == "producer_name") {
        return "Wybór producenta";
      } else {
        return "Wszystko";
      }
    },
  },
  watch: {
    saleCreateView(newVal) {
      this.dialog = newVal;
    },
    loading(newVal) {
      this.dataLoading = newVal;
    },
  },
  props: {
    saleCreateView: {
      type: Boolean,
      required: true,
    },
    apiLink: {
      type: String,
      required: true,
    },
  },
  methods: {
    closeCreateView() {
      this.$emit("closeCreateView");
    },
    convertDate(date) {
      var dd = String(date.getDate()).padStart(2, "0");
      var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = date.getFullYear();
      return `${yyyy}-${mm}-${dd}`;
    },
    getData() {
      if (this.type == "category_name") {
        this.getTypeData(
          `${this.apiLink}/api/views/products/getCategories.php`
        );
      } else if (this.type == "producer_name") {
        this.getTypeData(
          `${this.apiLink}/api/views/products/getProductors.php`
        );
      } else {
        this.typeData = [];
      }
    },
    async getTypeData(url) {
      this.dataLoading = true;
      await this.axios({
        method: "post",
        url: url,
        data: {
          token: localStorage.token,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.typeData = res.data;
          } else {
            this.$emit(
              "showAlert",
              `Błąd podczas pobierania promocji. Kod błędu API: ${res.status}`,
              "error"
            );
          }
        })
        .catch((err) => {
          this.showAlert(`Błąd pobierania promocji. Błąd: ${err}`, "error");
        });
      this.dataLoading = false;
    },
    async createSale() {
      this.loading = true;
      let sale = {
        datetime_start: `${this.dateFrom} ${this.timeFrom}`,
        datetime_end: `${this.dateTo} ${this.timeTo}`,
        discount_percent: this.discount,
        name: this.name,
        type: this.type,
        type_name: this.typeId,
      };
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/sales/createSale.php`,
        data: {
          token: localStorage.token,
          sale,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data == 1) {
              this.$emit("showAlert", "Promocja została dodana.", "success");
            } else {
              this.$emit(
                "showAlert",
                `Błąd podczas dodawania promocji. Promocja nie została dodana. Bład w API: ${res.data}`,
                "error"
              );
            }
            this.$emit("getSales");
          } else {
            this.$emit(
              "showAlert",
              `Błąd podczas dodawania promocji. Promocja nie została dodana. Kod błędu API: ${res.status}`,
              "error"
            );
          }
        })
        .catch((err) => {
          this.$emit(
            "showAlert",
            `Błąd podczas dodawania promocji. Promocja nie została dodana. Błąd: ${err}`,
            "error"
          );
        });
      this.closeCreateView();
      this.loading = false;
    },
    validateCreateSale() {
      this.formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;

        this.$refs[f].validate(true);
      });

      if (!this.formHasErrors) {
        this.createSale();
      }
    },
  },
};
</script>